<template>
  <base-section
    id="tillminkarafiende"
  >
    <base-section-heading title="Till min kära fiende">
    <p>Till min kära fiende kan beskrivas som en brevroman i pjäsform, inte helt olikt A.R. Gurneys Love Letters, 
     där två skådespelare läser brev och annan korrespondens. Den handlar om Alexander, en bortskämd förmögen ung 
     man som träffar sin överkvinna Ylva, en fotokonstnär som brottas med sin skaparångest. Det är två helt olika 
     världar som möts när Alexander skickar ett reklamationsbrev som resulterar i att Ylva får sparken från sitt 
     jobb, och deras liv tar en helt oväntad vändning. Vi får följa deras allt mer absurda brevväxling medan deras 
     känslor pendlar mellan hat och kärlek. Men var kommer det att sluta?
    </p>
    <p>
     Pjäsen är skriven av Bill Corbett och översatt till svenska av Johker Scenkonst.
    </p>
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
            <youtube 
              video-id="-yfCsFIUiq8">
            </youtube>

      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
    }),
  }
</script>
